import http from '@/middleware';

const thingApi = {
    /**
     * Recurso para obtener el árbol de grupos -> equipos para los filtros
     * @returns
     */
    async getNested(thingType = null) {
        const path =
            thingType != null
                ? `/api/thing/all-nested?thingType=${thingType}`
                : "/api/thing/all-nested";
        const response = await http.get(path);
        return response;
    },

    /**
     * Recurso para obtener el árbol / listado de tipos de servicio para los filtros 
     */
    async getServiceTypes() {
        const response = await http.get("/api/thing/all-service-types");
        return response;
    },

    async getVarillaThingConfigByThingIds(ids) {
        const response = await http.post("/api/thing/varillaThingConfigByThingIds", { ids });
        return response.data;
    },

    async getThingsByIdWithProjection(idsThing, projection) {
        const result = await http.post('/api/thing/getThingsByIdWithProjection', {thingIds: idsThing, projection: projection})
        return result.data;
    }
}

export default thingApi
