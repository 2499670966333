import SelectorDialog from "@/components/commons/dialog/SelectorDialog.vue";
import ListTreeViewComponent from "@/components/commons/list/tree-view/ListTreeViewComponent.vue";
import { filterTypes } from "@/constants/constants";
import deepcopy from "deepcopy";
import i18n from "@/i18n";
import { filterService } from "@/business/filterService";
import { getIds } from "@/tools/functions";
import { VARILLA_REPORT_BY } from "@colven/common-domain-lib/lib/constants/reports-constants";
/**
 * SECCIÓN GENERAL
 *
 * MODELO: {
 *  selectedThings: [],
 *  serviceType: [],
 * reportType: string,
 *  maxQuantity: string
 * }
 *
 * PROPS:
 * ---filterType: constante que indica el tipo de filtro, si es por EQUIPOS o CHOFERES. Se setearía según alguna de las constantes filterTypes. No está implementado full ya que por ahora solo se contempla que sea de EQUIPOS.
 */
export default {
  name: "GeneralSectionComponent",
  model: {
    prop: "model",
    event: "change"
  },
  components: {
    SelectorDialog,
    ListTreeViewComponent,
  },
  data: () => ({
    selectorDialogModel: {
      normalizer: (node) => {
        return {
          id: node.id,
          label: node.name,
          children: node.children,
        };
      },
      data: [],
      selected: null,
      show: false,
      title: i18n.t("filters.title"),
      singleSelect: false,
      selectAll: false,
      saveDisabled: false,
    },
    currentFilter: undefined,
    showGroupEquipmentOrDriverFilter: false,
    showReportTypeFilter: false,
    showServiceTypeFilter: false,
    showMaxQuantityFilter: false,
    showGeneralSection: false,
    equipmentsFilter: {
      id: "equipmentsFilter",
      name: i18n.t("filters.entityName.things"),
      data: [],
      selectedData: [],
      disabled: false,
      selectAction: undefined,
      singleSelect: false,
    },
    serviceTypeFilter: {
      id: "serviceTypeFilter",
      name: i18n.t("filters.entityName.serviceType"),
      data: [],
      selectedData: [],
      disabled: false,
      selectAction: undefined,
      singleSelect: true,
    },
    reportByFilter: {
      id: "reportByFilter",
      name: i18n.t("filters.entityName.reportBy"),
      data: [{
        name: i18n.t('filters.tabGeneral.selector.odometer'), id: VARILLA_REPORT_BY.ODOMETER
      }],
      selectedData: [],
      disabled: false,
      selectAction: undefined,
      singleSelect: true,
    },
    rules: {
      invalidValue: (value) => !value || value > 0 || `${i18n.t('filters.error.invalidValue')}`,
      limitReached: (value, limit) => !value || value <= Number(limit) || `${i18n.t('filters.error.maxLimitReached', limit)}`,
    }
  }),
  props: {
    model: {
      type: Object,
      required: true,
    },
    maxQuantityLimit: {
      type: Number,
      required: true
    },
    filterType: {
      type: String,
      required: false,
      default: filterTypes.EQUIPMENTS,
    },
    showReportType: {
      type: Boolean,
      required: false,
      default: false,
    },
    isIdling: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  created() {
  },
  async mounted() {
    // cargar el contenido correcto para el filtro general
    if (!this.isIdling) {
      this.reportByFilter.data.push({
        name: i18n.t('filters.tabGeneral.selector.hourmeter'),
        id: VARILLA_REPORT_BY.HOURMETER});
    }

    // cargar datos para el selector de grupos -> equipos
    this.equipmentsFilter.data = await filterService.getEnterpriseGroupEquipmentTreeSelector("LOGISTIC_TRUCK");

    // cargar datos para el selector de tipos de servicio
    this.serviceTypeFilter.data = await filterService.getServiceTypesTreeSelector();

    //inicialización de la data seleccionada para los selectores
    this.equipmentsFilter.selectedData = this.model.selectedThings;
    this.serviceTypeFilter.selectedData = this.model.serviceType;
  },
  methods: {
    arrowIcon(show) {
      if (show) {
        return "arrow_drop_up";
      } else {
        return "arrow_drop_down";
      }
    },
    getIds(data, singleSelect) {
      if (singleSelect) {
        return data.length === 1 ? data[0].id : null;
      } else {
        const result = [];
        getIds(data, result);
        return result;
      }
    },
    /**
     *
     */
    saveDialogAction(data) {
      this.currentFilter.selectorDialogItems = deepcopy(data);
      if (data) {
        const selectedItems = [];
        if (this.currentFilter.singleSelect && data) {
          selectedItems.push(data);
        }
        let itemsCopy;

        if (this.currentFilter.id === "equipmentsFilter" && this.showReportType) {
          // el siguiente filtro quita de los datos, aquellos vehiculos que no corresponden al tipo de reporte
          const equipmentFilteredData = [];
          for (const vehicle of this.currentFilter.data) {
            const children = vehicle.children.filter(equipment => equipment.reportBy === this.model.reportBy);
            equipmentFilteredData.push({ ...vehicle, children });
          }
          itemsCopy = deepcopy(equipmentFilteredData);
        } else {
          itemsCopy = deepcopy(this.currentFilter.data);
        }
        const filteredData = itemsCopy.filter(function filterFunction(item) {
          if (data.includes(item.id)) return true;
          if (item.children && item.children.length > 0) {
            return (item.children = item.children.filter(filterFunction))
              .length;
          }
        });
        selectedItems.cleanAndUpdate(filteredData);
        this.currentFilter.selectedData.cleanAndUpdate(selectedItems);
        if (this.currentFilter.id === "equipmentsFilter") {
          this.model.selectedThings.cleanAndUpdate(selectedItems)
        }
        else {
          this.model.serviceType.cleanAndUpdate(selectedItems)
        }
      } else {
        this.currentFilter.selectedData.clean();
        if (this.currentFilter.id === "equipmentsFilter") {
          this.model.selectedThings.clean()
        }
        else {
          this.model.serviceType.clean()
        }
      }
      if (this.currentFilter.selectAction) this.currentFilter.selectAction();
      this.selectorDialogModel.show = false;
    },

    /**
     * Se carga el modelo de datos del diálogo de selección
     * @param {*} filterData
     */
    selectEntities(filterData) {
      this.currentFilter = filterData;
      if (this.showReportType && filterData.id === "equipmentsFilter") {
        const newData = [];
        for (const vehicle of filterData.data) {
          // Filtra los vehiculos que va a mostrar segun su tipo de reporte
          const children = vehicle.children.filter(equipment => equipment.reportBy === this.model.reportBy);
          newData.push({ ...vehicle, children });
        }
        this.selectorDialogModel.data = newData;
      } else {
        this.selectorDialogModel.data = this.currentFilter.data;
      }
      /*
        Si no hay datos en la prop selectorDialogItems, lo cual significaría la primer apertura del diálogo
        para ese filtro, se verifica que no haya datos en la prop selectedData (si se configuraron unos datos
        seleccionados por defecto), o sino, se setea en null.
      */
      this.selectorDialogModel.selected = filterData.selectedData.length > 0 && this.currentFilter.selectorDialogItems ?
        this.currentFilter.selectorDialogItems : filterData.selectedData.length > 0 ?
          this.getIds(filterData.selectedData, filterData.singleSelect) : null;

      this.selectorDialogModel.title = this.$t(
        "filters.selectionDialog.title",
        { entityName: filterData.name }
      );
      this.selectorDialogModel.singleSelect = filterData.singleSelect;
      this.selectorDialogModel.selectAll = false;
      this.selectorDialogModel.show = true;
    },

    cancelDialogAction() {
      this.selectorDialogModel.show = false;
    },
    cleanSelectedVehicles() {
      this.equipmentsFilter.selectedData.clean();
      if (this.currentFilter?.id === "equipmentsFilter") {
        this.currentFilter.selectedData.clean();
      }
      this.model.selectedThings.clean();
      this.selectorDialogModel.selected = null;
    }
  },
};
