import { YieldUnits, TimeRanges, SortOptions, CustomTimeRangeTypes } from '@colven/common-domain-lib/lib';

export default {
    generalData: {
        selectedThings: [],
        serviceType: [],
        reportBy: "ODOMETER",
        maxQuantity: 10,
    },
    dateData: {
        selectedDateAndTimeRange: TimeRanges.LAST_HOUR,
        selectedDateAndTimeCustomRangeType: CustomTimeRangeTypes.DATE,
        sinceDate: null,
        sinceTime: null,
        toDate: null,
        toTime: null
    },
    configurationsData: {
        selectedYieldUnit: YieldUnits.LITERS_PER_100KM,
    },
    orderData: {
        selectedSortOption: SortOptions.ALPHABETIC_ASC_NAME,
    },
    rangeData: {
        greaterThan: null,
        lessThan: null
    }
}