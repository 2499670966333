import { VARILLA_REPORT_BY, YieldUnits } from '@colven/common-domain-lib/lib/constants/reports-constants';
import store from "@/store/store";
import i18n from "@/i18n";
                
/**
 * SECCIÓN DE CONFIGURACIÓN
 *
 * MODELO: {
 *  selectedYieldUnit: string
 * }
 * PROPS:
 * ---showsFuelData: booleano que indica si mostrar o no el tab Configuración, en base a si el reporte muestra
 *  información de combustible o no. Opcional, puesto como TRUE por defecto.
 */
export default {
    name: "ConfigurationsSectionComponent",
    model: {
        prop: "model",
        event: "change"
    },
    props: {
        model: {
            type: Object,
            required: true,
        },
        reportBy: {
            type: String,
            required: true
        },
        showsFuelData: {
            type: Boolean,
            required: false,
            default: true,
        },
    },
    data: () => ({
        showConfigurationsSection: false,
        showYieldUnit: true,
    }),
    computed: {
        yieldUnits() {
            const internationalization = store.getters['user/getInternationalization'];
            const internationalizationType = internationalization.unit;
            if (this.reportBy === VARILLA_REPORT_BY.ODOMETER) {
                
                if(internationalizationType === 'imperial') {
                    // this.model.selectedYieldUnit = YieldUnits.MILES_PER_GALLON;
                    return [
                        {
                            id: YieldUnits.MILES_PER_GALLON,
                            text: i18n.t("filters.tabConfigurations.selector.milesPerGallon"),
                        }
                    ];
                } 
                // this.model.selectedYieldUnit = YieldUnits.KM_PER_LITER;
                return [
                    {
                        id: YieldUnits.LITERS_PER_100KM,
                        text: i18n.t("filters.tabConfigurations.selector.litersPer100Km"),
                    },
                    {
                        id: YieldUnits.KM_PER_LITER,
                        text: i18n.t("filters.tabConfigurations.selector.kmPerLiter"),
                    }
                ];
            }

            if (this.reportBy === VARILLA_REPORT_BY.HOURMETER) {
                if (internationalizationType === 'imperial') {
                    // this.model.selectedYieldUnit = YieldUnits.GALLONS_PER_HOUR;
                    return [
                        {
                            id: YieldUnits.GALLONS_PER_HOUR,
                            text: i18n.t("filters.tabConfigurations.selector.gallonsPerHour"),
                        },
                        {
                            id: YieldUnits.HOURS_PER_GALLON,
                            text: i18n.t("filters.tabConfigurations.selector.hoursPerGalon"),
                        }
                    ];
                }
                // this.model.selectedYieldUnit = YieldUnits.LITERS_PER_HOUR;
                return [
                    {
                        id: YieldUnits.HOURS,
                        text: i18n.t("filters.tabConfigurations.selector.hoursPerLitre"),
                    },
                    {
                        id: YieldUnits.LITERS_PER_HOUR,
                        text: i18n.t("filters.tabConfigurations.selector.litersPerOur"),
                    }
                ];
               
            }
        }
    },
    methods: {
        arrowIcon(show) {
            console.log(this.model)
            if (show) {
                return "arrow_drop_up";
            } else {
                return "arrow_drop_down";
            }
        }
    },
};