import crayolasJson from '@/assets/crayolas'
import i18n from '@/i18n'
import { CustomTimeRangeTypes, TimeRanges, Util, YieldUnits } from '@colven/common-domain-lib/lib'
import jStat from 'jstat'
import moment from 'moment'
import store from "../store/store";
import { unitParser } from '@colven/common-domain-lib/lib';

/**
 * Método para seleccionar aleatoriamente los colores de los gráficos de torta/dona
 * @param {*} usedColors
 */
export const pickColor = function (usedColors) {
  let color = crayolasJson[Math.floor(Math.random() * crayolasJson.length)].hex
  if (usedColors && usedColors.length > 0) {
    let i = 0
    while (i < crayolasJson.length && usedColors.includes(color)) {
      color = crayolasJson[Math.floor(Math.random() * crayolasJson.length)].hex
      i++
    }
    return color
  } else {
    return color
  }
}
/**
 * Para comparar objetos
 * @param {*} o1
 * @param {*} o2
 */
export const objectsEqual = function (o1, o2) {
  if (!o1 && !o2) {
    return true
  } else {
    return typeof o1 === 'object' && Object.keys(o1).length > 0
      ? Object.keys(o1).length === Object.keys(o2).length &&
      Object.keys(o1).every(p => objectsEqual(o1[p], o2[p]))
      : o1 === o2
  }
}
/**
 * Para comparar arrays
 * @param {*} a1
 * @param {*} a2
 */
export const arraysEqual = function (a1, a2) {
  return a1.length === a2.length && a1.every((o, idx) => objectsEqual(o, a2[idx]))
}
/**
 * Función para mostrar un elemento html en pantalla completa
 * @param {*} elem
 */
export const toggleFullscreen = function (elem) {
  elem = elem || document.documentElement
  if (!document.fullscreenElement && !document.mozFullScreenElement &&
    !document.webkitFullscreenElement && !document.msFullscreenElement) {
    if (elem.requestFullscreen) {
      elem.requestFullscreen()
    } else if (elem.msRequestFullscreen) {
      elem.msRequestFullscreen()
    } else if (elem.mozRequestFullScreen) {
      elem.mozRequestFullScreen()
    } else if (elem.webkitRequestFullscreen) {
      elem.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT)
    }
    return 'fullscreen_exit'
  } else {
    if (document.exitFullscreen) {
      document.exitFullscreen()
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen()
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen()
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen()
    }
    return 'fullscreen'
  }
}

/**
 * Función para eliminar hijos de una lista multinivel
 * @param {*} list
 * @param {*} children
 */
export const removeChildren = function (list, children) {
  children.forEach(child => {
    removeItem(list, child)
  })
}

/**
 * Función recursiva para eliminar un hijo de una lista multinivel
 * @param {*} list
 * @param {*} item
 */
export const removeItem = function (list, item) {
  list.forEach(object => {
    if (object.id === item.id) {
      return list.splice(list.indexOf(object), 1)
    } else if (object.children && object.children.length > 0) {
      removeItem(object.children, item)
    }
  })
}

/**
 * Función para agregar un hijo (o un arreglo de hijos) a una lista multinivel
 * @param {*} list
 * @param {*} item
 */
export const addToList = function (list, item) {
  if (item instanceof Array) {
    item.forEach(i => {
      addItemToList(list, i)
    })
  } else {
    addItemToList(list, item)
  }
}

/**
 * Función recursiva para agregar un hijo a una lista multinivel
 * @param {*} list
 * @param {*} item
 */
export const addItemToList = function (list, item) {
  if (!item.father) {
    list.push(item)
  } else {
    list.forEach(listItem => {
      if (listItem.id === item.father && listItem.children) {
        listItem.children.push(item)
      } else if (listItem.id !== item.father && listItem.children) {
        addItemToList(listItem.children, item)
      }
    })
  }
}

/**
 * Devuelve los nodos hoja de una lista andidada
 * @param {*} list
 * @param {*} result
 */
export const getChildrenFromList = function (list, result) {
  list.forEach(item => {
    if (item.children) {
      getChildrenFromList(item.children, result)
    } else {
      result.push(item)
    }
  })
}

/**
 * Búsqueda recursiva en una lista anidada
 * @param {*} list
 * @param {*} id
 */
export const findById = function (list, id) {
  for (let node of list) {
    if (node.id === id) return node

    if (node.children) {
      let desiredNode = findById(node.children, id)
      if (desiredNode) return desiredNode
    }
  }
  return false
}

/**
 * Devuelve los ids de los elementos de la lista (si tienen hijos, devuelve los id de los hijos)
 * @param {*} list
 * @param {*} result
 */
export const getIds = function (list, result) {
  list.forEach(item => {
    if (item.children && item.children.length > 0) {
      getIds(item.children, result)
    } else {
      result.push(item.id)
    }
  })
}

export const getItemsById = function (list, ids, result) {
  ids.forEach(id => {
    getItemById(list, id, result)
  })
}

export const getItemById = function (list, id, result) {
  list.forEach(item => {
    if (item.id === id) {
      result.push(item)
    } else if (item.children && item.children.length > 0) {
      getItemById(item.children, id, result)
    }
  })
}

export const getDateTimeRange = function (selectedDateAndTimeRange, selectedDateAndTimeRangeType, sinceDate, sinceTime, toDate, toTime) {
  let tFrom
  let tTo
  const to = moment()
  const from = moment()
  let shiftStart
  switch (selectedDateAndTimeRange) {
    case TimeRanges.CURRENT_HOUR:
      // currentHour
      tFrom = to.startOf('hour').unix()
      tTo = from.unix()
      break
    case TimeRanges.LAST_HOUR:
      // lastHour
      tFrom = to.subtract(1, 'hours').startOf('hour').unix()
      tTo = from.startOf('hour').unix()
      break
    case TimeRanges.LAST_SIX_HOUR:
      // lastSixHours
      tFrom = to.subtract(6, 'hours').startOf('hour').unix()
      tTo = from.startOf('hour').unix()
      break
    case TimeRanges.TODAY:
      // today
      tFrom = to.startOf('day').unix()
      tTo = from.startOf('hour').unix()
      break
    case TimeRanges.YESTERDAY:
      // yesterday
      tFrom = to.subtract(1, 'days').startOf('day').unix()
      tTo = from.subtract(1, 'days').endOf('day').unix()
      break
    case TimeRanges.CURRENT_WEEK:
      // currentWeek
      tFrom = to.startOf('week').unix()
      tTo = from.startOf('hour').unix()
      break
    case TimeRanges.LAST_WEEK:
      // lastWeek
      tFrom = to.subtract(1, 'weeks').startOf('week').unix()
      tTo = from.subtract(1, 'weeks').endOf('week').unix()
      break
    case TimeRanges.LAST_TWO_WEEKS:
      // lastWeek
      tFrom = to.subtract(2, 'weeks').startOf('week').unix()
      tTo = from.subtract(1, 'weeks').endOf('week').unix()
      break
    case TimeRanges.CURRENT_MONTH:
      // lastWeek
      tFrom = to.startOf('month').unix()
      tTo = from.startOf('hour').unix()
      break
    case TimeRanges.LAST_TWO_MONTHS:
      // lastWeek
      tFrom = to.subtract(1, 'months').startOf('month').unix()
      tTo = from.startOf('hour').unix()
      break
    case TimeRanges.ENTERPRISE:
      // enterprise
      shiftStart = JSON.parse(localStorage.getItem('enterpriseTimeSpan')).start
      if ((to.hour() + to.minutes() / 60) < shiftStart) {
        tFrom = to.subtract(1, 'days').hour(Math.trunc(shiftStart)).minutes(shiftStart % 1).unix()
      } else {
        tFrom = to.hour(Math.trunc(shiftStart)).minutes(shiftStart % 1).unix()
      }
      tTo = from.unix()
      break
    case TimeRanges.HARVEST_START:
      // desde inicio de zafra
      tFrom = Number(JSON.parse(localStorage.getItem('harvestStartTime')).start)
      tTo = from.unix()
      break
    case TimeRanges.CUSTOM:
      // custom
      switch (selectedDateAndTimeRangeType) {
        case CustomTimeRangeTypes.DATE_AND_TIME:
          // date and time
          tTo = moment(toDate + ' ' + toTime, 'YYYY-MM-DD hh:mm').unix()
          tFrom = moment(sinceDate + ' ' + sinceTime, 'YYYY-MM-DD hh:mm').unix()
          break
        case CustomTimeRangeTypes.DATE:
          // only date
          tTo = moment(toDate, 'YYYY-MM-DD').unix()
          tFrom = moment(sinceDate, 'YYYY-MM-DD').unix()
          break
        case CustomTimeRangeTypes.TIME:
          // only time
          // TO DO: todavía no se implementó en el backend
          break
      }
      break
  }
  return { tFrom, tTo }
}

export const sortFunction = function (a, b) {
  const nameA = a.name.toLowerCase()
  const nameB = b.name.toLowerCase()
  if (nameA < nameB) {
    return -1
  } else if (nameA > nameB) {
    return 1
  } else {
    return 0
  }
}

export const dateSortFunction = function (a, b, format) {
  const dateA = moment(a, format)
  const dateB = moment(b, format)
  if (!dateA.isValid() && dateB.isValid()) {
    return -1
  } else if (dateA.isValid() && !dateB.isValid()) {
    return 1
  } else if (!dateA.isValid() && !dateB.isValid()) {
    return 0
  } else if (dateA.isBefore(dateB)) {
    return -1
  } else if (dateA.isAfter(dateB)) {
    return 1
  } else {
    return 0
  }
}

/**
 * Funciones para los cálculos al pie de la tabla
 * @param {*} numbers
 * @param {*} calc
 */
export const statisticFunction = function (numbers, id) {
  switch (id) {
    case 0:
      return jStat.sum(numbers)
    case 1:
      return jStat.mean(numbers)
    case 2:
      return jStat.median(numbers)
    case 3:
      return jStat.stdev(numbers)
    case 4:
      return jStat.variance(numbers)
    case 5:
      return jStat.min(numbers)
    default:
      console.error(`Calc ${id} not exists!`)
  }
}

/**
 * Función para validar el rango de fechas personalizado del selector, utiliza el valor del formulario
 * @param {*} selectedDateAndTimeRange
 * @param {*} selectedDateAndTimeRangeCustomType
 * @param {*} customDateTimeValidForm
 * @param {*} sinceDate
 * @param {*} sinceTime
 * @param {*} toDate
 * @param {*} toTime
 */
export const selectorDateTimeValidation = function (selectedDateAndTimeRange, selectedDateAndTimeRangeCustomType, customDateTimeValidForm, sinceDate, sinceTime, toDate, toTime) {
  return (selectedDateAndTimeRange === TimeRanges.CUSTOM && !customDateTimeValidForm) || (selectedDateAndTimeRange === TimeRanges.CUSTOM && dateAndTimeRangeEmptyFields(selectedDateAndTimeRangeCustomType, sinceDate, sinceTime, toDate, toTime, false))
}

export const dateAndTimeRangeEmptyFields = function (selectedDateAndTimeRangeCustomType, sinceDate, sinceTime, toDate, toTime, all) {
  switch (selectedDateAndTimeRangeCustomType) {
    case CustomTimeRangeTypes.DATE_AND_TIME:
      if (all) {
        return !toDate && !toTime && !sinceDate && !sinceTime
      } else {
        return !toDate || !toTime || !sinceDate || !sinceTime
      }
    case CustomTimeRangeTypes.DATE:
      if (all) {
        return !toDate && !sinceDate
      } else {
        return !toDate || !sinceDate
      }
    case CustomTimeRangeTypes.TIME:
      if (all) {
        return !toTime && !sinceTime
      } else {
        return !toTime || !sinceTime
      }
  }
}

/**
 * Convertir UNIX timestamp a ISODate
 * @param {*} unixTimestamp
 * @returns
 */
export const ISODateFromUnixTimestamp = (unixTimestamp) => {
  const date = new Date(unixTimestamp * 1000)
  const dateString = date.toString() !== 'Invalid Date' ? date.toISOString().split('T')[0] : ''
  const timeStringParts = date.toLocaleTimeString().split(':')
  const timeString = timeStringParts[0] + ':' + timeStringParts[1]
  return {
    date: dateString,
    time: (timeString !== '0:00') ? timeString : ''
  }
}

/**
 * Convertir un timestamp a string
 * @param {*} timestamp
 * @returns
 */
export const parseTimestamp = (timestamp) => {
  if (timestamp) {
    const offset = Number(store.getters["user/getEnterpriseTimezone"]);
    const internationalization = store.getters['user/getInternationalization'];
    const internationalizationTime = internationalization.date;
    const internationalizationDate = internationalization.dateMask;
    if (internationalizationTime == '24hs') {
      return moment.unix(timestamp).utcOffset(offset).format(internationalizationDate.toUpperCase() + ' HH:mm:ss');
    }
    else {
      return moment.unix(timestamp).utcOffset(offset).format(internationalizationDate.toUpperCase() + ' hh:mm:ss a');
    }
  }
  return "";
}

/**
 * Obtener la URL para ver un punto en Google Maps
 * @param {*} lat
 * @param {*} long
 * @returns
 */
export const getGoogleMapsURL = (lat, long) => {
  return `https://www.google.com/maps/search/?api=1&query=${lat}%2C${long}`
}

/**
 * Formatear velocidad y dirección
 * @param {*} speed
 * @param {*} direction
 * @returns
 */
export const speedToStringTranslated = (speed, direction) => {
  if (speed || speed === 0) {
    let cardinalPoint = '';
    if (direction === 0) {
      cardinalPoint = i18n.t('utils.NORTH');
    } else if (direction === 90) {
      cardinalPoint = i18n.t('utils.EAST');
    } else if (direction === 180) {
      cardinalPoint = i18n.t('utils.SOUTH');
    } else if (direction === 270) {
      cardinalPoint = i18n.t('utils.WEST');
    } else if (direction > 0 && direction < 90) {
      cardinalPoint = i18n.t('utils.NORTH_EAST');
    } else if (direction > 90 && direction < 180) {
      cardinalPoint = i18n.t('utils.SOUTH_EAST');
    } else if (direction > 180 && direction < 270) {
      cardinalPoint = i18n.t('utils.SOUTH_WEST');
    } else if (direction > 270 && direction < 360) {
      cardinalPoint = i18n.t('utils.NORTH_WEST');
    }
    if (cardinalPoint.length > 0) {
      return unitParser.parseSpeed(speed.toFixed(2), store.getters['user/getInternationalization'].unit, true) + ' ' + ' ' + cardinalPoint;
    } else {
      return unitParser.parseSpeed(speed.toFixed(2), store.getters['user/getInternationalization'].unit, true) + ' ';
    }
  } else {
    return '';
  }
}

/**
 * Función para calcular el consumo según la unidad seleccionada
 * @param {*} fuelConsumed
 * @param {*} distance
 * @param {*} yieldUnit
 * @returns
 */
export const calcPerformance = (fuelConsumed, distance, yieldUnit) => {
  fuelConsumed = Number(fuelConsumed)
  distance = Number(distance)
  let result = 0
  switch (yieldUnit) {
    case YieldUnits.LITERS_PER_100KM:
      result = distance ? Util.roundDecimals((fuelConsumed / distance) * 100) : 0.0
      break
    case YieldUnits.KM_PER_LITER:
      result = fuelConsumed ? Util.roundDecimals((distance / fuelConsumed)) : 0.0
      break
    case YieldUnits.MILES_PER_GALLON:
      result = fuelConsumed ? Util.roundDecimals(((distance * 0.621371) / (fuelConsumed * 0.264172))) : 0.0
      break
    case YieldUnits.HOURS: // Horas por litro
      result = fuelConsumed ? Util.roundDecimals(((distance) / fuelConsumed)) : 0.0
      break
    case YieldUnits.LITERS_PER_HOUR: //Litros por hora
      result = fuelConsumed ? Util.roundDecimals((fuelConsumed / (distance))) : 0.0
      break
    case YieldUnits.GALLONS_PER_HOUR: // Galones por hora
      result = fuelConsumed ? Util.roundDecimals((fuelConsumed * 0.264172 / (distance))) : 0.0
      break
    case YieldUnits.HOURS_PER_GALLON: //Horas por Galon
      result = fuelConsumed ? Util.roundDecimals(((distance) / (fuelConsumed * 0.264172))) : 0.0
      break
    default:
      // KM_PER_LITER
      result = fuelConsumed ? Util.roundDecimals((distance / fuelConsumed)) : 0.0
      break
  }
  return result
}

/**
 * Obtener la unidad
 * @param {*} yieldUnit
 * @returns
 */
export const getYieldUnit = (yieldUnit) => {
  switch (yieldUnit) {
    case YieldUnits.LITERS_PER_100KM:
      return 'L/Km'
    case YieldUnits.KM_PER_LITER:
      return 'Km/L'
    case YieldUnits.MILES_PER_GALLON:
      return 'Mi/gal'
    case YieldUnits.HOURS:
      return 'Hs/L'
    case YieldUnits.HOURS_PER_GALLON:
      return 'Hs/gal'
    case YieldUnits.LITERS_PER_HOUR:
      return 'L/Hs'
    case YieldUnits.GALLONS_PER_HOUR:
      return 'gal/Hs'
    default:
      // KM_PER_LITER
      return 'Km/L'
  }
}

export const getRandomColor = () => {
  return "#" + Math.floor(Math.random() * 16777215).toString(16);
}

export const processFilters = (filters) => {
  const result = { idThings: [] };
  if (filters && filters.generalData && filters.generalData.selectedThings && filters.generalData.selectedThings.length > 0) {
    result.idThings = getIdThings(filters.generalData.selectedThings);
  }
  if (filters.generalData) {
    result.limit = filters.generalData.maxQuantity;

    if (filters.generalData.serviceType && filters.generalData.serviceType.length > 0) {
      result.serviceTypes = filters.generalData.serviceType.map((serviceType) => serviceType.key);
    }
    if (filters.generalData.reportBy) {
      result.reportBy = filters.generalData.reportBy;
    }
  }

  if (filters.dateData) {
    const offset = store.getters["user/getEnterpriseTimezone"];
    if (TimeRanges.CURRENT_HOUR === filters.dateData.selectedDateAndTimeRange) {
      filters.dateData.selectedDateAndTimeRange = TimeRanges.CUSTOM;
      const from = moment().utc().add(offset, "hours").milliseconds(0).minutes(0).seconds(0);
      const to = moment().utc().add(offset, "hours").add(1, "hour").milliseconds(0).minutes(0).seconds(0);
      filters.dateData.sinceDate = from.format('YYYY-MM-DD');
      filters.dateData.toDate = to.format('YYYY-MM-DD');
      filters.dateData.sinceTime = from.format('HH:mm');
      filters.dateData.toTime = to.format("HH:mm");
      filters.dateData.selectedDateAndTimeCustomRangeType = CustomTimeRangeTypes.DATE_AND_TIME;
    }
    result.reporFixedKey = filters.dateData.selectedDateAndTimeRange;
    if (filters.dateData.selectedDateAndTimeRange === TimeRanges.CUSTOM) {
      result.sinceDate = filters.dateData.sinceDate;
      result.toDate = filters.dateData.toDate;
      if (filters.dateData.selectedDateAndTimeCustomRangeType === 'date') {
        result.sinceTime = '00:00';
        result.toTime = '23:59';
      } else {
        result.sinceTime = filters.dateData.sinceTime;
        result.toTime = filters.dateData.toTime;
      }
      result.from = moment(`${result.sinceDate} ${result.sinceTime}:00`, 'YYYY-MM-DD HH:mm:ss').utcOffset(offset, true).unix();
      result.to = moment(`${result.toDate} ${result.toTime}:59`, 'YYYY-MM-DD HH:mm:ss').utcOffset(offset, true).unix();
    }
  }
  if (filters.orderData) {
    result.sort = filters.orderData.selectedSortOption;
  }
  if (filters.rangeData && (filters.rangeData.greaterThan || filters.rangeData.lessThan)) {
    const unit = store.getters['user/getInternationalization'].unit;
    result.rangeGreaterThan = (filters.rangeData.greaterThan && Number(filters.rangeData.greaterThan));
    result.rangeLessThan = (filters.rangeData.lessThan && Number(filters.rangeData.lessThan));
    if (unit === 'imperial') {
      result.rangeGreaterThan = result.rangeGreaterThan * 3.785;
      result.rangeLessThan = result.rangeLessThan * 3.785;
    }
  }
  if (filters.configurationsData && filters.configurationsData.selectedYieldUnit) {
    result.yieldUnit = filters.configurationsData.selectedYieldUnit;
  }
  return result;
}

function getIdThings(groups) {
  let result = [];
  for (const group of groups) {
    if (group.enterpriseId) {
      result.push(group.id);
    }
    if (group.children) {
      result = result.concat(getIdThings(group.children));
    }
  }
  return result;
}

export const parseToGallonPerMiles = (litersPer100Km) => {
  return (litersPer100Km * 0.004251).toFixed(2); //1 litro por 100km = 0.004251 galones por milla (US)
}

export const getNowLongDateUTC = () => {
  return moment().utc().valueOf() / 1000;
}

export const calculatePercentage = (value, total) => {
  if (value && total !== 0) {
    return (value * 100 / total);
  }
  return 0.0;
}

export const compareStringNumber = (a, b, stringToRemove) => {
  let valueA = null;
  let valueB = null;
  if (a) {
    try {
      const tempValue = a.replace(stringToRemove, '')
      if (isNaN(tempValue) || tempValue === 'Infinity') {
        valueA = null;
      } else {
        valueA = parseFloat(tempValue);
      }
    } catch (e) {
      valueA = null;
    }
  }
  if (b) {
    try {
      const tempValue = b.replace(stringToRemove, '')
      if (isNaN(tempValue) || tempValue === 'Infinity') {
        valueB = null;
      } else {
        valueB = parseFloat(tempValue);
      }
    } catch (e) {
      valueB = null;
    }
  }
  if ((valueA !== 0 && !valueA) && valueB) {
    return 1
  } else if (valueA && (valueB !== 0 && !valueB)) {
    return -1
  } else if ((valueA !== 0 && !valueA) && (valueB !== 0 && !valueB)) {
    return 0
  } else if (valueA > valueB) {
    return -1
  } else if (valueA < valueB) {
    return 1
  } else {
    return 0
  }
}

export const compareNumberSplitString = (a, b, stringToSplit) => {
  let valueA = null;
  let valueB = null;
  if (a) {
    valueA = a.split(stringToSplit)[0];
  }
  if (b) {
    valueB = b.split(stringToSplit)[0];
  }
  return compareStringNumber(valueA, valueB, '');
}

export const lightenDarkenColor = (col, amt) => {
  var usePound = false;
  if (col[0] == "#") {
    col = col.slice(1);
    usePound = true;
  }

  var num = parseInt(col, 16);

  var r = (num >> 16) + amt;

  if (r > 255) r = 255;
  else if (r < 0) r = 0;

  var b = ((num >> 8) & 0x00ff) + amt;

  if (b > 255) b = 255;
  else if (b < 0) b = 0;

  var g = (num & 0x0000ff) + amt;

  if (g > 255) g = 255;
  else if (g < 0) g = 0;

  return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
}

export const applyOffsetFilters = (filters) => {
  const offset = store.getters["user/getEnterpriseTimezone"];
  filters.from = filters.from + (parseInt(offset) * 60 * 60);
  filters.to = filters.to + (parseInt(offset) * 60 * 60);
}

/**
 * Validar rango personalizado (SÓLO FECHA)
 * Se valida que satisfaga la condición de que el rango no sea mayor a 1 mes entre las fechas y que
 * el rango sea válido, es decir, que la fecha "desde" siempre esté antes que la fecha "hasta"
 * @param {*} dateFrom
 * @param {*} dateTo
 * @returns
 */
export const validateCustomDateRange = (dateFrom, dateTo) => {
  const momentTo = moment(dateTo, 'YYYY-MM-DD')
  const momentFrom = moment(dateFrom, 'YYYY-MM-DD')
  const diff = momentTo.diff(momentFrom, 'months', true)
  const satisfiesTimeGap = diff <= 1;
  const satisfiesValidRange = momentTo.isSameOrAfter(momentFrom);
  return satisfiesTimeGap && satisfiesValidRange;
}

/**
 * Validar rango personalizado (FECHA Y HORA)
 * Se valida que satisfaga la condición de que el rango no sea mayor a 1 mes entre las fechas y que
 * el rango sea válido, es decir, que la fecha "desde" siempre esté antes que la fecha "hasta"
 * @param {*} dateFrom
 * @param {*} dateTo
 * @param {*} timeFrom
 * @param {*} timeTo
 * @returns
 */
export const validateCustomDateAndTimeRange = (dateFrom, dateTo, timeFrom, timeTo) => {
  const momentFrom = moment(`${dateFrom} ${timeFrom}`, 'YYYY-MM-DD HH:mm')
  const momentTo = moment(`${dateTo} ${timeTo}`, 'YYYY-MM-DD HH:mm')
  const diff = momentTo.diff(momentFrom, 'months', true)
  const satisfiesTimeGap = diff <= 1;
  const satisfiesValidRange = momentTo.isSameOrAfter(momentFrom);
  return satisfiesTimeGap && satisfiesValidRange;
}